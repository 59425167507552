import { SetStateAction, useState } from "react"
import styled from "styled-components"

import { endOfDay, startOfDay } from "date-fns"

import { EventLog } from "src/components/EventLog/EventLog"
import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import {
  eventGroups,
  mapEventGroup,
} from "src/data/events/logic/eventConstants"
import { eventOptionFilter } from "src/data/events/logic/eventsLogic"
import { useFetchEvents } from "src/data/events/queries/eventQueries"
import { TEventGroupValues } from "src/data/events/types/eventTypes"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { DateRangePicker } from "src/ui/DateRangePicker/DateRangePicker"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import { TSelectOption } from "src/ui/DropdownSelect/DropdownSelect"
import { MainView } from "src/ui/Layout/MainView"
import { spacing } from "src/ui/spacing"

export function EventsView() {
  const cigaretteSmokeAvailability = useFeatureAvailability({
    feature: "cigarette_smoke_detection",
  })

  const sensorSwapAvailability = useFeatureAvailability({
    feature: "sensor_swap",
  })

  const { limit, offset, setOffset } = useUrlPager()
  const { _t } = useTranslate()
  const { orgId } = useOrganization()

  const translatedLabels = eventGroups
    .map((e) => ({
      ...e,
      label: _t(e.label),
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const filteredEventOptions = translatedLabels.filter((eventOption) =>
    eventOptionFilter({
      eventOption,
      availability: {
        cigarette_smoke_detection: cigaretteSmokeAvailability.available,
        sensor_swap: sensorSwapAvailability.available,
      },
    })
  )
  const [eventOptions, setEventOptions] = useState(filteredEventOptions)
  const [eventGroupValues, _setEventGroupValues] = useState<
    TEventGroupValues[]
  >([])
  function setEventGroupValues(p: SetStateAction<TEventGroupValues[]>) {
    setOffset(0) // offset should be reset whenever a filter is applied
    _setEventGroupValues(p)
  }

  const [homeIds, setHomeIds] = useState<TSelectOption<string>["value"][]>([])
  const [dateRange, setDateRange] = useState<{
    startDate: Date | null
    endDate: Date | null
  }>({
    startDate: null,
    endDate: null,
  })
  const [homeSearch, setHomeSearch] = useState("")
  const fetchHomes = useFetchHomes({
    orgId,
    filters: { name: homeSearch },
  })
  const homes = fetchHomes.data?.homes || []
  const homeOptions: TSelectOption<string>[] = homes.map((h) => ({
    value: h.home_id,
    label: h.name || h.home_id,
  }))

  function transformEventFilterOptions(ids: TEventGroupValues[]) {
    return Array.from(new Set(ids.map((id) => mapEventGroup[id]).flat()))
  }

  const maxEventsReached =
    transformEventFilterOptions(eventGroupValues).length >= 30

  const fetchEvents = useFetchEvents({
    limit,
    offset,
    organizationId: orgId,
    eventIdFilter: transformEventFilterOptions(eventGroupValues),
    homeIds: homeIds,
    startAt: !!dateRange.startDate
      ? startOfDay(dateRange.startDate).toISOString()
      : undefined,
    endAt: !!dateRange.endDate
      ? endOfDay(dateRange.endDate).toISOString()
      : undefined,
  })

  const events = fetchEvents.data?.events || []
  const totalCount = fetchEvents.data?.paging?.total_count

  function handleEventsChange({
    checked,
    option,
  }: {
    checked: boolean
    option: TSelectOption<TEventGroupValues>
  }) {
    // Limiting to 30 events in the events parameter so that
    // we do not exceed the BE character limit on the events parameter
    if (checked) {
      if (!maxEventsReached) {
        setEventGroupValues((prev) => [...prev, option.value])
      }
    } else {
      setEventGroupValues((prev) => prev.filter((o) => o !== option.value))
    }
  }

  function handleHomeChange({
    checked,
    option,
  }: {
    checked: boolean
    option: TSelectOption<string>
  }) {
    if (checked) {
      // At this point he BE will accept up to about(750 chars) 30 ids
      // in the home_ids parameter so we are limiting the number of
      // homeOptions sent to to the BE to 30
      setHomeIds((prev) =>
        prev.length < 30 ? Array.from(new Set([...prev, option.value])) : prev
      )
    } else {
      setHomeIds((prev) => prev.filter((o) => o !== option.value))
    }
  }

  function handleEventsSearch(searchValue: string) {
    setEventOptions(() =>
      filteredEventOptions.filter((option) =>
        option.label
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase())
      )
    )
  }

  function handleHomeSearch(searchValue: string) {
    setHomeSearch(searchValue)
  }

  function handleDateRangeChange({
    startDate,
    endDate,
  }: {
    startDate: Date
    endDate: Date
  }) {
    setDateRange({
      startDate,
      endDate,
    })
  }

  return (
    <MainView title={_t(langKeys.log)} size="unset">
      <div>
        <FilterBox>
          <DropdownMultiSelect
            label={_t(langKeys.home, { count: 1 })}
            placeholder={_t(langKeys.search)}
            options={homeOptions}
            selectedValues={homeIds}
            onChange={handleHomeChange}
            onSearch={handleHomeSearch}
            onClearSelection={() => setHomeIds([])}
            loading={fetchHomes.isLoading}
          />

          <DropdownMultiSelect
            label={_t(langKeys.filter_event_type)}
            placeholder={_t(langKeys.search)}
            options={eventOptions}
            selectedValues={eventGroupValues}
            onChange={handleEventsChange}
            onSearch={handleEventsSearch}
            onClearSelection={() => setEventGroupValues([])}
            loading={fetchEvents.isLoading}
            errorMsg={maxEventsReached && "Maximum number of events reached"}
          />
          <DateRangePicker
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            onDateRangeChange={handleDateRangeChange}
            dateRangeProps={{
              minBookingDays: 2,
            }}
          />
        </FilterBox>
      </div>

      <EventLog
        events={events}
        loading={fetchEvents.isLoading}
        isFiltered={eventGroupValues.length > 0}
      />

      <PagerWrapper>
        <Pager
          limit={limit}
          offset={offset}
          setOffset={setOffset}
          totalCount={totalCount}
        />
      </PagerWrapper>
    </MainView>
  )
}

const PagerWrapper = styled.div`
  margin-top: ${spacing.XL};
`

const FilterBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.M};
  margin-bottom: ${spacing.M};
`
